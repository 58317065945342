// import React from "react";
// import { Box, Typography, Container } from "@mui/material";

// export const Reject = () => {
//   return (
//     <Container maxWidth="sm">
//       <Box
//         display="flex"
//         justifyContent="center"
//         alignItems="center"
//         height="100vh"
//         flexDirection="column"
//       >
//         <Typography variant="h4" sx={{ color: "red", fontWeight: "bold" }}>
//           Thank you
//         </Typography>
//         {/* <Typography variant="body1" sx={{ color: "black", marginTop: "10px" }}>
//           Your order has been Reject.
//         </Typography> */}
//       </Box>
//     </Container>
//   );
// };
import React from "react";
import { Box, Typography, Container } from "@mui/material";

export const Reject = () => {
  return (
    <Container
      maxWidth="md" // Change this to "md" or another breakpoint to increase the width
      sx={{
        border: "1px solid #ccc", // Light gray border
        borderRadius: "8px", // Rounded corners
        padding: "16px", // Adjusted padding for the container
        margin: "auto", // Center the Container horizontally
        height: "auto", // Height adjusted to content size
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Shadow effect
        textAlign: "center", // Center the text inside the Container
        position: "absolute", // Use absolute positioning
        top: "50%", // Center vertically
        left: "50%", // Center horizontally
        transform: "translate(-50%, -50%)", // Adjust for exact centering
        width: "40%", // Ensure full width is used for the container
        maxWidth: "500px", // Set a larger maxWidth for the container
      }}
    >
      <Box>
        <Typography variant="h5" sx={{ color: "#2596be", fontWeight: "bold" }}>
          Thank you
        </Typography>
        <Typography variant="h6" sx={{ color: "black", fontSize: "18px" }}>
          Your order has been rejected , For further assistant please contant
          <Box
            component="span"
            sx={{
              display: "inline-block",
              padding: "0 5px", // Padding around the email text
              borderRadius: "4px", // Roundness for the email box
              marginLeft: "4px", // Add space between text and email
              color: "#bf262f", // Blue color for the email
              fontSize: "18px", // Adjusted font size
              fontWeight: "bold", // Make the email bold
            }}
          >
            cs@imagefirstportal.com
          </Box>
        </Typography>
      </Box>
    </Container>
  );
};
