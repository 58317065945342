import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ProductPage from "./components/ProductPage";
// import { ThankYou } from "./components/ThankYou";
// import { ThankYou } from './components/ThankYou';
import { ThankYou } from "./components/Tankyou";
import { Reject } from "./components/Reject";
import Card from "./components/Card";
// import { MessagePage } from "./components/MessagePage";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<ProductPage />} />
        <Route path="/card" element={<Card />} />
        <Route path="/thankyou" element={<ThankYou />} />
        <Route path="/reject" element={<Reject />} />
        {/* <Route path="/MessagePage" element={<MessagePage />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
