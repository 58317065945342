import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  AppBar,
  Toolbar,
  Container,
  Grid,
  CircularProgress,
} from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import MindcrewLogo from "../assets/Mindcrew.png";
import Swal from "sweetalert2";
import Loader from "./Loader";

function ButtonAppBar({ email }) {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ backgroundColor: "#00008B" }}>
        <Toolbar>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
              width: "100%",
            }}
          >
            <Typography
              variant="h6"
              component="div"
              sx={{ textAlign: "center" }}
            >
              {email}
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

const Card = () => {
  const [orderDetails, setOrderDetails] = useState(null);
  const [ceoOrder, setCeoOrder] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Add a loading state
  const navigate = useNavigate();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const url = window.location.href;
  const urlParams = new URLSearchParams(new URL(url).search);
  const id = urlParams.get("id");
  // const { id } = useParams();
  const [isData, setisData] = useState(false);
  // const location = useLocation();
  // const getQueryParams = (search) => {
  //   return new URLSearchParams(search);
  // };

  // // Get the id from query parameters
  // const queryParams = getQueryParams(location.search);
  // const id = queryParams.get("id");
  useEffect(() => {
    if (id) {
      const fetchOrderDetails = async () => {
        try {
          const response = await axios.get(
            `https://imagefirstuniformsportal.com:3000/api/orderDetailsById?id=${id}`
          );
          setOrderDetails(response?.data);
          setisData(response?.data?.status);
          setCeoOrder(response?.data?.order?.customer?.note);
          setIsLoading(false); // Set loading to false after data is fetched
        } catch (error) {
          console.error("Error fetching order details:", error);
          setIsLoading(false); // Set loading to false even if there is an error
          setisData(null);
        }
      };

      fetchOrderDetails();
    } else {
      setIsLoading(false); // Stop loading if no id is provided
      // setisData(null);
    }
  }, [id]);

  // const handleButtonClick1 = () => {
  //   Swal.fire({
  //     html: '<span style="font-size: 30px;">Are you sure you want reject this order?</span>',
  //     icon: "error",
  //     showCancelButton: true,
  //     confirmButtonText: "Yes",
  //     cancelButtonText: "No",
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       rejectOrderStatus();
  //     }
  //   });
  // };
  const handleButtonClick1 = () => {
    Swal.fire({
      html: '<span style="font-size: 30px;">Are you sure you want to reject this order?</span>',
      icon: "error",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      preConfirm: () => {
        // This will show a loading spinner inside the popup
        // Swal.showLoading();
        // Simulate an asynchronous operation
        // return new Promise((resolve) => {
        // setTimeout(() => {
        //   // Call your function to reject the order status
        //   rejectOrderStatus();
        //   // Resolve the promise to close the alert
        //   resolve(true);
        // },5000); // Simulate delay for showing loader
        // });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        // You can add any further logic here after the order is rejected
        setIsLoading(true);
        rejectOrderStatus();

        console.log("Order rejected");
      }
    });
  };

  const handleButtonClick = () => {
    Swal.fire({
      html: '<span style="font-size: 30px;">Are you sure you want to approve this order?</span>',
      icon: "success",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      preConfirm: () => {
        // Show a loading spinner inside the popup
        // Swal.showLoading();
        // Simulate an asynchronous operation (e.g., approving the order)
        // return new Promise((resolve) => {
        //   // setTimeout(() => {
        //   //   // Call your function to update the order status
        //   //   updateOrderStatus();
        //   //   // Resolve the promise to close the alert
        //   //   resolve(true);
        //   // }, 5000); // Simulated delay for showing loader
        // });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        updateOrderStatus();
        // Add any further logic here after the order is approved
        console.log("Order approved");
      }
    });
  };

  const updateOrderStatus = async () => {
    const url =
      "https://imagefirstuniformsportal.com:3000/api/updateOrderStatus";
    const data = {
      orderId: id,
      status: true,
      // cEO_email: "shreyadeshmukh926@gmail.com",
      cEO_email: ceoOrder,
    };

    setIsButtonDisabled(true);

    try {
      const response = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.data.message) {
        setIsLoading(false);
        setIsSuccess(true);
        navigate("/thankyou", { replace: true });
      } else {
        setIsLoading(false);
        setIsSuccess(false);
      }
    } catch (error) {
      setIsSuccess(false);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
      setIsButtonDisabled(false);
    }
  };

  const rejectOrderStatus = async () => {
    const url =
      "https://imagefirstuniformsportal.com:3000/api/updateOrderStatus";
    const data = {
      orderId: id,
      status: false,
      cEO_email: ceoOrder,
    };

    setIsButtonDisabled(true);

    try {
      const response = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.data.message) {
        setIsSuccess(false);
        setIsLoading(false);
        navigate("/reject", { replace: true });
      } else {
        setIsLoading(false);
        setIsSuccess(false);
      }
    } catch (error) {
      setIsSuccess(false);
      setIsLoading(false);
    } finally {
      setIsButtonDisabled(false);
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <Loader isLoading={isLoading} />
      <ButtonAppBar />
      <Container maxWidth="md">
        {isLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="50vh"
          >
            {/* <CircularProgress /> */}
          </Box>
        ) : isData ? (
          <Box p={3}>
            <Box mb={3} pl={2} display="flex" justifyContent="center">
              <img
                src={MindcrewLogo}
                alt="Mindcrew Logo"
                width="100px"
                height="100px"
              />
            </Box>
            {/* Loader */}
            {isLoading ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="50vh"
              >
                <CircularProgress />
              </Box>
            ) : (
              <>
                {/* Grid Section */}
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={1}>
                    <Grid
                      item
                      xs={6}
                      sx={{
                        backgroundColor: "#F0EBE3",
                        padding: "10px",
                        borderRadius: "5px",
                      }}
                    >
                      <Box
                        mb={4}
                        pl={2}
                        sx={{
                          padding: "10px",
                          borderRadius: "5px",
                        }}
                      >
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          mb={2}
                        >
                          <Box>
                            <Typography
                              variant="body1"
                              sx={{
                                color: "black", // Change text color to black
                                fontWeight: "bold", // Make the text bold
                                marginTop: "10px",
                              }}
                            >
                              Billing address
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{
                                color: "black",
                                lineHeight: 1.5,
                                marginTop: "5px",
                              }}
                              style={{ fontWeight: "bold" }}
                            >
                              {orderDetails?.order?.billing_address?.name}
                              <br />
                              <span style={{ fontWeight: "bold" }}>
                                {orderDetails?.order?.billing_address?.city}
                              </span>
                              <br />
                              <span style={{ fontWeight: "bold" }}>
                                {orderDetails?.order?.billing_address?.city}{" "}
                                {orderDetails?.order?.billing_address?.province}{" "}
                                {orderDetails?.order?.billing_address?.zip}
                              </span>
                              <br />
                              <span style={{ fontWeight: "bold" }}>
                                {orderDetails?.order?.billing_address?.country}
                              </span>
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box mb={4} pl={2}>
                        <Typography
                          variant="h6"
                          sx={{
                            fontWeight: "bold",
                            marginBottom: "10px",
                          }}
                        >
                          Payment Summary
                        </Typography>
                        <Typography
                          variant="body1"
                          gutterBottom
                          sx={{
                            borderBottom: "1px solid #ccc",
                            paddingBottom: "5px",
                            display: "flex",
                            justifyContent: "space-between", // Aligns content to the left and right
                            fontWeight: "normal", // Normal weight for the labels
                          }}
                        >
                          <div>
                            <strong>Order Number:</strong>
                            <br />
                            <span style={{ fontSize: "0.875rem" }}>
                              <strong>Order Date:</strong>
                            </span>
                            <br />
                            <strong style={{ fontSize: "0.875rem" }}>
                              Status:
                            </strong>
                          </div>

                          <div
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            #{orderDetails?.order?.order_number}
                            <br />
                            <span style={{ fontSize: "0.875rem" }}>
                              {new Date(
                                orderDetails?.order?.created_at
                              ).toLocaleDateString("en-CA")}
                            </span>
                            <br />
                            {orderDetails?.order?.financial_status}
                          </div>
                        </Typography>

                        <Typography
                          variant="body1"
                          gutterBottom
                          sx={{
                            borderBottom: "1px solid #ccc",
                            paddingBottom: "5px",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <strong style={{ fontSize: "0.875rem" }}>
                            P.O. Number:
                          </strong>
                          {/* {orderDetails?.order?.po_number} */}
                          <span>
                            {orderDetails?.order?.po_number
                              ? orderDetails.order?.po_number
                              : "N/A"}
                          </span>
                        </Typography>

                        {/* fgwefg */}
                        <Typography
                          variant="body1"
                          gutterBottom
                          sx={{
                            borderBottom: "1px solid #ccc",
                            paddingBottom: "5px",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <strong style={{ fontSize: "0.875rem" }}>
                            Ship Via:
                          </strong>
                          <span style={{}}>
                            {orderDetails?.order?.financial_status
                              ? orderDetails.order?.financial_status
                              : "N/A"}
                          </span>
                        </Typography>

                        <Box>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            width="100%"
                          >
                            <Typography variant="body1" component="span">
                              <strong style={{ fontSize: "0.875rem" }}>
                                Subtotal:
                              </strong>
                            </Typography>
                            <Typography variant="body1" component="span">
                              ${orderDetails?.order?.subtotal_price}
                            </Typography>
                          </Box>

                          <Box
                            display="flex"
                            justifyContent="space-between"
                            width="100%"
                          >
                            <Typography variant="body1" component="span">
                              <strong style={{ fontSize: "0.875rem" }}>
                                Shipping:
                              </strong>
                            </Typography>
                            <Typography variant="body1" component="span">
                              $
                              {
                                orderDetails?.order?.total_shipping_price_set
                                  ?.shop_money?.amount
                              }
                            </Typography>
                          </Box>

                          <Box
                            display="flex"
                            justifyContent="space-between"
                            width="100%"
                          >
                            <Typography variant="body1" component="span">
                              <strong style={{ fontSize: "0.875rem" }}>
                                Tax:
                              </strong>
                            </Typography>
                            <Typography variant="body1" component="span">
                              ${orderDetails?.order?.total_tax}
                            </Typography>
                          </Box>

                          <Box
                            display="flex"
                            justifyContent="space-between"
                            width="100%"
                          >
                            <Typography variant="body1" component="span">
                              <strong style={{ fontSize: "0.875rem" }}>
                                Total:
                              </strong>
                            </Typography>
                            <Typography variant="body1" component="span">
                              ${orderDetails?.order?.subtotal_price}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                  {/* New Section for Order Summary */}
                  <Box mt={2} p={2}>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{ color: "#000000" }} // Dark black color
                    >
                      Order Summary
                    </Typography>

                    <Box
                      sx={{
                        maxHeight: "700px", // Adjust this value as needed
                        overflowY: "auto", // Enables vertical scrolling
                        padding: "16px", // Optional: Adds padding inside the container
                        // border: "1px solid #ddd", // Optional: Adds a border for visual clarity
                      }}
                    >
                      {orderDetails?.order?.line_items?.map((item, index) => (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "16px",
                          }}
                        >
                          {/* Display the first image from image_urls */}
                          {item.image_urls && item.image_urls.length > 0 && (
                            <img
                              src={item.image_urls[0].src}
                              alt={item.title}
                              style={{
                                width: "50px",
                                height: "50px",
                                marginRight: "16px",
                              }}
                            />
                          )}
                          <div style={{ flexGrow: 1 }}>
                            <Typography
                              variant="body1"
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <span>
                                {item.title} × {item.quantity}
                              </span>
                              <Box sx={{ fontWeight: "bold", color: "black" }}>
                                ${item.price * item.quantity}
                              </Box>
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              {item.variant_title}
                            </Typography>
                          </div>
                        </div>
                      ))}
                    </Box>
                  </Box>
                </Box>

                {/* Buttons */}
                <Box mt={2} display="flex" justifyContent="center">
                  <Button
                    onClick={handleButtonClick}
                    variant="contained"
                    sx={{
                      backgroundColor: isSuccess ? "green" : "blue",
                      borderRadius: "0px",
                      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.2)",
                      width: "150px",
                      mr: 2,
                    }}
                  >
                    Approve
                  </Button>
                  <Button
                    onClick={handleButtonClick1}
                    variant="contained"
                    color="error"
                    sx={{
                      borderRadius: "0px",
                      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.2)",
                      width: "150px",
                    }}
                  >
                    Reject
                  </Button>
                </Box>
              </>
            )}
          </Box>
        ) : isData !== null ? (
          <Container
            maxWidth="md"
            sx={{
              border: "1px solid #ccc",
              borderRadius: "8px",
              padding: "16px",
              margin: "auto",
              height: "auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              textAlign: "center",
              // position: "center",
              top: "200%",
              left: "50%",
              // transform: "translate(-50%, -50%)",
              width: "100%",
              maxWidth: "500px",
              marginTop: "100px",
            }}
          >
            <Box
              sx={{
                minHeight: "350px",
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Typography
                variant="h4"
                sx={{ color: "#2596be", fontWeight: "bold" }}
              >
                Your response has already been recorded
              </Typography>

              <Box
                component="span"
                sx={{
                  display: "inline-block",
                  padding: "0 5px",
                  borderRadius: "4px",
                  marginLeft: "4px",
                  color: "#bf262f",
                  fontSize: "24px",
                  fontWeight: "bold",
                  marginTop: "10px", // Adds some space between the two text elements
                }}
              >
                For further assistance, please contact
              </Box>
              <Typography
                sx={{ color: "#bf262f", fontSize: "24px", fontWeight: "bold" }}
              >
                cs@imagefirstportal.com
              </Typography>
            </Box>
          </Container>
        ) : (
          <Container
            maxWidth="md"
            sx={{
              border: "1px solid #ccc",
              borderRadius: "8px",
              padding: "16px",
              margin: "auto",
              height: "auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              textAlign: "center",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "40%",
              maxWidth: "500px",
            }}
          >
            <Box
              sx={{
                minHeight: "350px",
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Typography
                variant="h4"
                sx={{ color: "#2596be", fontWeight: "bold" }}
              >
                The site is under maintenance!
              </Typography>

              <Box
                component="span"
                sx={{
                  display: "inline-block",
                  padding: "0 5px",
                  borderRadius: "4px",
                  marginLeft: "4px",
                  color: "#bf262f",
                  fontSize: "24px",
                  fontWeight: "bold",
                  marginTop: "10px", // Adds some space between the two text elements
                }}
              >
                Please try after sometime.
              </Box>
            </Box>
          </Container>
        )}
      </Container>
    </div>
  );
};

export default Card;
