import React from 'react'
import { TailSpin } from 'react-loader-spinner'

function Loader({isLoading}) {
  return (
    <div style={{position:"absolute", top:"50vh", left:"50vw"}}>
    <TailSpin
  visible={isLoading}
  height="80"
  width="80"
  color="#bf262f"
  ariaLabel="tail-spin-loading"
  radius="1"
  />
  </div>
  )
}

export default Loader